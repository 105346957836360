import React from 'react';

import s from '../styles/vrste-transporta.module.css';

export default () => (
  <section className={s.vrsteTransportaSection}>
    <div className="container">
      <div className="section-header">
        <h2>Vrste transporta</h2>
      </div>
      <div className="row justify-center">
        <div className="col-md-6 col-lg-5">
          <div className={s.card}>
            <img
              src="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_350,f_auto/v1551116348/keso-promet/silo-transport-kamion.jpg"
              alt="Silo transport slika"
            />
            <div className={s.textContainer}>
              <h3>Silo transport</h3>
              <p>Vršimo usluge prevoza svih praškastih materijala.</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-5">
          <div className={s.card}>
            <img
              src="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_350,f_auto/v1551388715/keso-promet/transport-kiper-cropped.jpg"
              alt="Transport kiperima i sanducarima slika"
            />
            <div className={s.textContainer}>
              <h3>Transport kiperima</h3>
              <p>Mogućnost prevoza svih rasutih tereta.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
