import React from 'react';

import s from '../styles/hero-section.module.css';

export default ({ children, cn }) => (
  <section className={`container-fluid ${s.heroSection} ${s[cn]}`}>
    <span className={s.overlay} />
    {/* <div className="hero-image"></div> */}
    {children}
  </section>
);
