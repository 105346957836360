import React from 'react';

import Layout from '../components/layout';
import HeroSection from '../components/hero-section';
import AboutSection from '../components/about-section';
import VrsteTransporta from '../components/vrste-transporta';
import SiloTransport from '../components/silo-transport';

export default () => (
  <Layout>
    <HeroSection cn="transport">
      <h2>Transport</h2>
    </HeroSection>
    <AboutSection>
      <p>
        Godinama prikupljano iskustvo u prevozu roba i uspješno poslovanje sa
        više partnera garancija su kvaliteta naših usluga: tačnost, pouzdanost i
        ekonomske cijene. Sva vozila su licencirana, redovno se održavaju i
        prolaze kontrolne tehničke ispravnosti.
      </p>
    </AboutSection>
    <VrsteTransporta />
    <SiloTransport />
  </Layout>
);
