import React from 'react';

export default () => (
  <section className="primary-bg">
    <div className="container">
      <div className="section-header">
        <h2>Silo transport</h2>
      </div>
      <div className="row justify-center">
        <div className="col-md-10">
          <p>
            U sferi trasporta počeli smo najvise da ulazemo u silo cisterne.
            Vršimo usluge prevoza svih praškastih materijala.
          </p>
          <p>
            Naše kip silo cisterne su podobne za prevoz granulata i prahova:
          </p>
          <ul className="transport-list">
            <li>Polietlien (LDPE, MDPE, HDPE, PE…)</li>
            <li>Polipropilen (homopolimeri and kopolimeri)</li>
            <li>
              Sulfat za industriju deterdženata (natrijum sulfat, sulfat
              monohidrat…)
            </li>
            <li>
              Karbonat za industriju deterdženata, stakla (kalcijum karbonat,
              natrijum karbonat, natrijum bikarbonat…)
            </li>
            <li>
              Komponente hrane za životinje (žitarice, krupica, sačma,
              proteinski koncentrati, monokalcijum fosfat…)
            </li>
            <li>
              Hrana za ljude (brašno, šećer, soli, skrob, žitarice, durum
              krupica…)
            </li>
            <li>Mineral (kaolin , feldspat, bentonit, talk, perlit…)</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);
