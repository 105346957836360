import React from 'react';
import { Link } from 'gatsby';

import logo from '../images/footer-logo.svg';

export default () => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <Link to="/">
            <img className="footer__logo" src={logo} alt="Keso-Promet logo" />
          </Link>
          <p className="copyright-text">
            © Copyright {new Date().getFullYear()} Keso-Promet d.o.o.
            <br />
            Sva prava zadržana.
          </p>
        </div>

        <div className="col-md-2 d-none d-md-block">
          <h6>Kompanija</h6>
          <ul className="list-group list-group-flush">
            <li>
              <Link to="/" className="list-group-item list-group-item-action">
                Početna
              </Link>
            </li>
            <li>
              <Link
                to="/#about"
                className="list-group-item list-group-item-action"
              >
                O nama
              </Link>
            </li>
            <li>
              <Link
                to="/#contact"
                className="list-group-item list-group-item-action"
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-4 d-none d-md-block">
          <h6>Djelatnosti</h6>
          <ul className="list-group list-group-flush">
            <li>
              <Link
                to="/prodaja-naftnih-derivata"
                className="list-group-item list-group-item-action"
              >
                Prodaja naftnih derivata
              </Link>
            </li>
            <li>
              <Link
                to="/transport"
                className="list-group-item list-group-item-action"
              >
                Transport
              </Link>
            </li>
            <li>
              <Link
                to="/zitopromet"
                className="list-group-item list-group-item-action"
              >
                Žitopromet
              </Link>
            </li>
            <li>
              <Link
                to="/proizvodnja-i-prodaja-betona"
                className="list-group-item list-group-item-action"
              >
                Proizvodnja i prodaja betona
              </Link>
            </li>
            <li>
              <Link
                to="/keso-invest"
                className="list-group-item list-group-item-action"
              >
                Prodaja stambeno-poslovnih prostora
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);
