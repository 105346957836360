import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';
import '../styles/bootstrap-reboot.css';
import '../styles/bootstrap-nav.css';
import '../styles/bootstrap-grid.css';
import '../styles/layout.css';

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet title={data.site.siteMetadata.title}>
          <meta name="title" content="Keso-Promet d.o.o." />
          <meta name="description" content="Keso-Promet d.o.o. je firma sa tradicijom dugom skoro 30 godina. Bavimo se trgovinom naftnih derivata, izvođenjem građevinskih radova, proizvodnjom betona, pružamo usluge transporta i proizvodnjom i prodajom mlinarskih proizvoda (Žitopromet d.o.o.)." />
          <meta name="keywords" content="kesopromet, keso-promet, keso promet, keso promet doo, keso promet bih, keso promet pumpe, keso pumpe, zitopromet, zitopromet zvornik, zitopromet karakaj, keso trsic, keso promet trsic, keso karakaj, keso promet karakaj, keso zvornik, keso promet zvornik, keso caparde, keso promet caparde, keso kamioni, keso transport, keso beton, keso promet betonara, keso zgrade, keso stanovi, keso invest, keso promet zgrade novi sad, keso novi sad, prodaja stambeno-poslovnih prostora, prodaja poslovnih objekata, prodaja stambenih prostora, prodaja nekretnina, stanovi novi sad" />
          <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0" />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.kesopromet.com/" />
          <meta property="og:title" content="Keso-Promet d.o.o." />
          <meta property="og:description" content="Keso-Promet d.o.o. je firma sa tradicijom dugom skoro 30 godina. Bavimo se trgovinom naftnih derivata, izvođenjem građevinskih radova, proizvodnjom betona, pružamo usluge transporta i proizvodnjom i prodajom mlinarskih proizvoda (Žitopromet d.o.o.)." />
          <meta property="og:image" content="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_650/v1562422155/keso-promet/IMG_6628_2.jpg" />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://www.kesopromet.com/" />
          <meta property="twitter:title" content="Keso-Promet d.o.o." />
          <meta property="twitter:description" content="Keso-Promet d.o.o. je firma sa tradicijom dugom skoro 30 godina. Bavimo se trgovinom naftnih derivata, izvođenjem građevinskih radova, proizvodnjom betona, pružamo usluge transporta i proizvodnjom i prodajom mlinarskih proizvoda (Žitopromet d.o.o.)." />
          <meta property="twitter:image" content="https://res.cloudinary.com/ridjis/image/upload/c_scale,h_650/v1562422155/keso-promet/IMG_6628_2.jpg" />
          <link
            rel="dns-prefetch"
            href="https://res.cloudinary.com/"
            crossorigin="anonymous"
          />
          <link
            rel="preconnect"
            href="https://res.cloudinary.com/"
            crossorigin="anonymous"
          />
        </Helmet>
        <Header />
        {children}
        <Footer />
      </>
    )}
  />
);
